import React from 'react'
import one from './1.svg'




export default function Footer() {
    return (

        <div style={{padding:"0rem 0rem",marginTop:'0rem',position:'relative'}} id="navba1" href="#about-us-section"
        class="scroll-button smoothscroll paddingLobby 
ff
        
        dn-M" >
        
        <div class="row  fotterPadding" style={{marginRight:"2.5rem"}}>
        
        <div class="col text-center1 yellow"  >
        
        <a target="_blank"  href="http://nabcglobal.org/" >
        
            <img style={{height:"35px",width:"35px"}} src={one} class="imgLogo "/>
            
             
            <p className="menuname " >HOME</p>
        </a>
        </div>
     
        

        
          </div>
        
        
        
        
        
        </div> 
    )
}
