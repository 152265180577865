import logo from './logo.svg';

import React ,{useState} from 'react'
import './App.css';
import Main from './images/login/1-Cover.jpg'
import Main1 from './images/login/2-Cover-copy-1.jpg'
import Second from './images/login/3-Objetives.jpg'
import thirdd from './images/login/Objetives (1).jpg'
import secnd from './images/login/4-Table-of-Content.jpg'
import forth from './images/login/5-Metrics-Response-Time-vs-Issue-Type.jpg'
import six from './images/login/last.jpg'
import test from './images/login/NABC LOGIN BAND-01.jpg'
import test2 from './images/login/Extended dates NABC GLOBAL.jpg'


import Home2 from './form2'
import Home3 from './form3'
import Home4 from './form4'
import Home from './homepage'
import Home5 from './form5'
import Home6 from './form6'

import footer from './images/login/foooter.png'
import Footer from './footer1'

function App() {
const [tab, setTab] = useState("STANDARD")

  return (

    <>

    <div className="footer1">

    <Footer />
       <div class="row " style={{marginTop:"-4.4rem"}} >
  
  <img class="img-fluid  img" src={Main}  style={{marginBottom:"1rem"}} />
  <a target="_blank" href="https://nabcglobal.virtuallive.in/login.html">
  <img class="img-fluid  img" src={test} style={{marginTop:"0rem"}} />
  </a>

  <img class="img-fluid  img" src={test2} style={{marginTop:"1rem"}} />
  <img class="img-fluid  img" src={Main1} style={{marginTop:"1rem"}} />

  

  
<div className="row  "  style={{backgroundColor:"#feefde",marginTop:"1rem"}} >
<div className="col-lg-12 text-center mx-auto " style={{fontSize:"36px",fontWeight:"700"}}>REGISTER HERE</div>
<div className="col-lg-12 text-center mx-auto " style={{fontSize:"26px",fontWeight:"700"}}>Credit Card or Bank Card</div>

<div className="row text-center ">
<div className="col-lg-3 "></div>
<div className="col-lg-1 m-2 p-2  text-center border flex items-center justify-center">
    
    <div className={`ba b--black br2 fw5 pointer pa2 mh2 tc ${tab=="STANDARD"?"blueColor":"normal"} ` } onClick={()=>setTab("STANDARD")}> STANDARD <br/> REGISTRATION </div>
   </div>
<div className="col-lg-1 m-2 p-2 text-center border flex  items-center justify-center">
  
   <div className={`ba b--black br2 fw5 pointer pa2 mh2 tc ${tab=="SPONSORS"?"blueColor":"normal"} ` } onClick={()=>setTab("SPONSORS")}>  SPONSORS <br/>REGISTRATION</div></div>

<div className="col-lg-1 m-2 p-2 text-center border flex items-center justify-center"> <div

onClick={()=>setTab("DONOR")}
className={`ba b--black br2 fw5 pointer pa2 mh2 tc ${tab=="DONOR"?"blueColor":"normal"} ` }> DONOR <br/> REGISTRATION</div></div>

<div className="col-lg-1 m-2 p-2 text-center border flex items-center justify-center"> 
<div className={`ba b--black br2 fw5 pointer pa2 mh2 tc ${tab=="PARTNER"?"blueColor":"normal"} ` }

onClick={()=>setTab("PARTNER")}
> PARTNER ORGANIZATION <br/>REGISTRATION</div></div>

<div className="col-lg-1 m-2 p-2 text-center border flex items-center justify-center black"> <div

onClick={()=>setTab("EXHIBITOR")}
className={`ba b--black br2 fw5 pointer pa2 mh2 tc ${tab=="EXHIBITOR"?"blueColor":"normal"} ` }> EXHIBITOR <br/>REGISTRATION</div> </div>


<div className="col-lg-1 m-2 p-2 text-center border flex items-center justify-center black"> <div

onClick={()=>setTab("COVID")}
className={`ba b--black br2 fw5 pointer pa2 mh2 tc ${tab=="COVID"?"blueColor":"normal"} ` }> COVID CARE <br/>DONATION</div> </div>


</div>

       
        
          
</div>


<div className="row"  style={{backgroundColor:"#feefde"}}>
  
  {tab=="STANDARD"  && <Home  tab={tab} />}</div>

 <div className="row" style={{backgroundColor:"#feefde"}}>
          {tab=="SPONSORS"  && <Home2  tab={tab} />}
          </div>
        
          <div className="row" style={{backgroundColor:"#feefde"}}>
          {tab=="DONOR"  && <Home3  tab={tab} />}
          </div>
        
         <div className="row" style={{backgroundColor:"#feefde"}}>
          {tab=="PARTNER"  && <Home4  tab={tab} />}
          </div>

          <div className="row" style={{backgroundColor:"#feefde"}}>  {tab=="EXHIBITOR"  && <Home5  tab={tab} />}</div> 

          <div className="row" style={{backgroundColor:"#feefde"}}>  {tab=="COVID"  && <Home6  tab={tab} />}</div>
        
          <img class="img-fluid  img" src={thirdd} style={{marginTop:"1rem"}} />
  <img class="img-fluid  img" src={secnd} style={{marginTop:"1rem"}} />
  
  <img class="img-fluid  img" src={six} style={{marginTop:"1rem"}} />
  
  <img class="img-fluid  img" src={forth} style={{marginTop:"1rem"}} />
  <img class="img-fluid  img" src={footer} style={{marginTop:"1rem"}} />


</div>


    </div>
    </>
  );
}



export default App;
