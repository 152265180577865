import React, { useState, useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import './form.css'
import axios from 'axios'
export default function CheckoutForm(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
  
    // Create PaymentIntent as soon as the page loads
    axios
      .post("https://nabcglobal.virtuallive.in:3002/create-payment-intent", {
     
       headers:{
          Accept:'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Access-Control-Allow-Origin':"*",
        
          },
      email:props.email,
      money:parseInt(props.money)*100

      })
      .then(res => {
        console.log(res)
        return res


      })
      .then(data => {
        console.log(data.data.clientSecret)
        setClientSecret(data.data.clientSecret);
      });
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
  
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
        receipt_email: props.email,
        
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });


    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {

console.log("22222")
      
        // axios.post('/paytrue',(req,res))
        axios.post(`https://nabcglobal.virtuallive.in:3002/${props.endPoint}`, {
            formType:props.formType,
        firstName:  props.firstName,
        lastName: props.lastName,
        Exhibitor:props.Exhibitor,
        gender: props.salutation,
        email: props.email,
        password: props.password,
        Organization:props.Organization,
    
        country: props.country,
        countryCode: props.countryCode,
    
        phoneNumber: props.phoneNumber,
    
        addressLine1: props.addressLine1,
        addressLine2: props.addressLine2,
    
        city: props.city,
        postalCode: props.postalCode,
        state: props.state,
        fee: payload.paymentIntent.amount,
      
      
        AffiliatedOrganization:props.Affiliated,
      
        price:payload.paymentIntent.amount,
      
  
        paymentId:payload.paymentIntent.id,
        client_secret:payload.paymentIntent.client_secret,
        status:payload.paymentIntent.status,
        payment_method_id:payload.paymentIntent.payment_method
     
          })
          .then((response) => {
            console.log(response);
          }, (error) => {
            console.log(error);
          });
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  return (
    <form className="payform" id="payment-form" onSubmit={handleSubmit} 
    
    style={{background:"white"}}>
      <div className="flex w-100 item-end justify-end mb3">
      
      <div className="w-100"></div>
      <div className=" pointer ml3"  onClick={()=>props.setShowPay(false)}>X</div>  
      </div>
      <div className=" pointer ml3"  onClick={()=>props.setShowPay(false)}>
          you have chosen {props.formType}     {props.email}   amount   ${props.money}
          
          </div>  
     

      <CardElement id="card-element"  options={cardStyle} onChange={handleChange} />
      <button
        disabled={processing || disabled || succeeded}
        id="submit"
      >
        <span id="button-text" >
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Pay Now"
          )}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? "result-message tc" : "result-message hidden tc" }>
      Welcome to NABC GLOBAL 2021.
Your online secure payment has been successfully processed. 
Please check the confirmation email in your inbox.
Go to NABCGLOBAL (<a href="https://nabcglobal.virtuallive.in/">Click Here)</a>
       
      </p>
    </form>
  );
}
